import {
  getUrlParameterByName,
  useAuthToken,
  usePageTracking,
} from '@unionfab/ufc-shop-commons';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import OrderCheckoutRoute from '@/features/home/pages/order-checkout';
import OrderHistoryRoute from '@/features/home/pages/order-history';
import OrderInvoice from '@/features/home/pages/order-invoice';
import OrderPaymentRoute from '@/features/home/pages/order-payment';
import ReceiveAddressRoute from '@/features/home/pages/receive-address';
import { SideMenu } from '@/features/home/SideMenu';
import NewQuoteRoute from '@/features/new_quote/routes/new-quote-route';
import NewShopQuoteRoute from '@/features/new_quote/routes/new-shop-quote-route';
import {
  OAuthRecvResponseHandler,
  OAuthRecvResponsePath,
} from '@/features/shared';

import { createLoadableRoute } from './loadable';
import QualityInspection from '@/features/quality_inspection';

const RedirectTo = ({ to }: { to: string }) => {
  console.log('redirect to ', to);
  useEffect(() => {
    const resetVerifyCode = getUrlParameterByName('resetVerifyCode');
    const email = getUrlParameterByName('email');
    if (resetVerifyCode && email) {
      if (!to.includes('resetVerifyCode')) {
        to = `${to}?resetVerifyCode=${resetVerifyCode}&email=${email}`;
      }
    }
    if (window.location.host === 'www.unionfab.com') {
      window.open(to, '_self');
    } else {
      window.open(`https://pre.unionfab.com/${to}`, '_self');
    }
  }, []);
  return <></>;
};

export const AppRoutes = () => {
  usePageTracking();
  useAuthToken();

  return (
    <Routes>
      <Route
        path={OAuthRecvResponsePath}
        element={<OAuthRecvResponseHandler />}
      />
      {createLoadableRoute({
        appId: 'pdf-viewer',
        path: 'pdfviewer',
        appLoader: () => import('@/features/home/pages/pdf-viewer'),
      })}

      {createLoadableRoute({
        appId: 'booking-video',
        path: 'booking_a_video',
        appLoader: () => import('@/features/home/pages/booking-video'),
      })}

      <Route path={'invoice/:orderCode'} element={<OrderInvoice />} />
      {/* {createLoadableRoute({
        appId: 'invoice',
        path: 'invoice/:orderCode',
        appLoader: () => import('@/features/home/pages/order-invoice'),
      })} */}

      {/* <Route path={'quality-inspection/'} element={<QualityInspection />} /> */}

      {createLoadableRoute({
        appId: 'qualityInspection',
        path: '/quality-inspection',
        appLoader: () => import('@/features/quality_inspection'),
      })}

      {createLoadableRoute({
        appId: 'cancel-subscribe-email',
        path: '/cancel-subscribe-email',
        appLoader: () => import('@/features/cancel-subscribe-email'),
      })}

      {createLoadableRoute({
        appId: 'quotation',
        path: 'quotation/:orderCode',
        appLoader: () => import('@/features/home/pages/order-quotation'),
      })}

      {createLoadableRoute({
        appId: 'mobile',
        path: 'm/*',
        appLoader: () => import('@/features/home/pages/mobile-entry'),
      })}

      <Route path="/" element={<SideMenu />}>
        {/* {createLoadableRoute({
          appId: 'new-quote',
          path: 'new_quote/*',
          appLoader: () =>
            import('@/features/new_quote/routes/new-quote-route'),
        })} */}
        <Route path={'new_quote/*'} element={<NewQuoteRoute />} />

        {createLoadableRoute({
          appId: 'anonymous-new-shop-quote',
          path: 'anonymous_new_shop_quote/*',
          appLoader: () =>
            import(
              '@/features/new_quote/routes/anonymous-new-shop-quote-route'
            ),
        })}
        {/* {createLoadableRoute({
            appId: 'new-shop-quote',
            path: 'new_shop_quote/*',
            appLoader: () =>
              import('@/features/new_quote/routes/new-shop-quote-route'),
          })} */}
        <Route path={'new_shop_quote/*'} element={<NewShopQuoteRoute />} />
        {/* <Route path={'new_shop_quote/*'} element={<NewQuoteRoute />} /> */}

        {/* {createLoadableRoute({
            appId: 'checkout',
            path: 'checkout/*',
            appLoader: () => import('@/features/home/pages/order-checkout'),
          })} */}
        <Route path={'checkout/*'} element={<OrderCheckoutRoute />} />

        {/* {createLoadableRoute({
            appId: 'receive-address',
            path: 'address/*',
            appLoader: () => import('@/features/home/pages/receive-address'),
          })} */}
        <Route path={'address/*'} element={<ReceiveAddressRoute />} />

        {/* {createLoadableRoute({
            appId: 'payment',
            path: 'payment/*',
            appLoader: () => import('@/features/home/pages/order-payment'),
          })} */}
        <Route path={'payment/*'} element={<OrderPaymentRoute />} />

        {/* {createLoadableRoute({
            appId: 'order-history',
            path: 'orders_history/*',
            appLoader: () => import('@/features/home/pages/order-history'),
          })} */}
        <Route path={'orders_history/*'} element={<OrderHistoryRoute />} />

        {createLoadableRoute({
          appId: 'discount',
          path: 'discount/*',
          appLoader: () => import('@/features/home/pages/discount'),
        })}

        {createLoadableRoute({
          appId: 'referral',
          path: 'referral/*',
          appLoader: () => import('@/features/home/pages/referral'),
        })}

        {createLoadableRoute({
          appId: 'rapid-consulting',
          path: 'rapid_consulting/*',
          appLoader: () => import('@/features/home/pages/rapid-consulting'),
        })}

        {createLoadableRoute({
          appId: 'account-setting',
          path: 'account_settings/*',
          appLoader: () => import('@/features/home/pages/account-center'),
        })}

        <Route path="/" element={<Navigate to="auth?request=true" />} />
      </Route>
      <Route path="register" element={<RedirectTo to="/auth/register" />} />
      <Route
        path="signup_waiting"
        element={<RedirectTo to="/auth/register" />}
      />
      <Route
        path="recovery"
        element={<RedirectTo to="/auth/change-password" />}
      />
      <Route
        path="reset_password"
        element={<RedirectTo to="/auth/reset-password" />}
      />
      <Route path="*" element={<Navigate to="auth?request=true" />} />
    </Routes>
  );
};
